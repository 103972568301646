html, body {
  margin: 0;
  padding: 0;
}


@font-face {
  font-display: swap;
  font-family: 'TargetBold';
  src: url('../assets/fonts/HelveticaforTarget-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'TargetHeavy';
  src: url('../assets/fonts/HelveticaforTarget-Heavy.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'TargetLight';
  src: url('../assets/fonts/HelveticaforTarget-Light.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'TargetMedium';
  src: url('../assets/fonts/HelveticaforTarget-Medium.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'TargetRm';
  src: url('../assets/fonts/HelveticaforTarget-RmPl.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'TargetThin';
  src: url('../assets/fonts/HelveticaforTarget-Thin.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'TargetRegular';
  src: url('../assets/fonts/HelveticaforTarget.ttf') format('truetype');
}

@import "toastr";

.toast-error, .toast-success {
  margin-top: 50vh;
  background-image: none !important;  
}
